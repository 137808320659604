import axios from 'axios'
import newError from './util/newError'
import formatDate from './util/formatDate'
import normalizeDate from './util/normalizeDate'
import validateDates from './util/validateDates'

const newDate = () => ({
  month: null,
  day: null,
  year: null,
})

export default function () {
  const {
    show = 'lookup',
    points = null,
    roomType = null,
    arrival = newDate(),
    departure = newDate(),
  } = JSON.parse(sessionStorage.getItem('ownerplusWidgetData')) || {}

  return {
    show, // "lookup" or "search"
    points,
    roomType,
    arrival,
    departure,
    loading: false,
    error: null,

    normalizeDate,

    init() {
      const notify = () => {
        const { roomType, arrival, departure, points } = this
        this.normalizeDate(['arrival', 'departure'])
        this.$dispatch('owner-updated', {
          roomType,
          arrival,
          departure,
          points,
        })
      }

      this.$watch('roomType', notify)
      this.$watch('arrival', notify)
      this.$watch('departure', notify)
      this.$watch('points', notify)
    },

    formattedPoints(ownerPoints = undefined) {
      const points = ownerPoints || (this.points ?? 0)
      return parseInt(points).toLocaleString('en-US')
    },

    submit() {
      this.error = this.validate()
      if (this.error) {
        return
      }

      this.loading = true

      const { roomType, arrival, departure } = this
      axios
        .post(this.$refs['lookupForm'].action, {
          roomType,
          arrival: formatDate(
            new Date(arrival.year, arrival.month, arrival.day)
          ),
          departure: formatDate(
            new Date(departure.year, departure.month, departure.day)
          ),
        })
        .then((response) => {
          const { points } = response?.data
          const ownerplusWidgetData = JSON.stringify({ points, show: 'search' })
          sessionStorage.setItem('ownerplusWidgetData', ownerplusWidgetData)

          this.points = points
          this.show = 'search'
          this.loading = false
        })
        .catch(({ response }) => {
          this.loading = false
          this.error = newError(
            response?.error ?? 'An unknown error occurred. Please try again.'
          )
        })
    },

    validate() {
      const { roomType, arrival, departure } = this

      if (null === roomType) {
        return newError('Please select a unit type.', ['roomType'])
      }

      return validateDates(arrival, departure)
    },

    reset() {
      this.show = 'lookup'
      sessionStorage.removeItem('ownerplusWidgetData')
    },

    getUrlParameter(parameterName) {
      const { search } = window.location
      const parameters = new URLSearchParams(search)
      return parameters.get(parameterName)
    },
  }
}
