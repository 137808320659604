import { feature } from 'topojson-client'
import { geoAlbers, geoConicEqualArea } from 'd3-geo'

function Projection () {
    const epsilon = 1e-6;

    var cache,
        cacheStream,
        lower48 = geoAlbers(), lower48Point,
        alaska = geoConicEqualArea().rotate([154, 0]).center([-2, 58.5]).parallels([55, 65]),
        alaskaPoint,
        hawaii = geoConicEqualArea().rotate([157, 0]).center([-3, 19.9]).parallels([8, 18]),
        hawaiiPoint,
        point,
        pointStream = { point: function (x, y) { point = [x, y]; } };

    function albersUsa (coordinates) {
        var x = coordinates[0], y = coordinates[1];
        return point = null,
            (lower48Point.point(x, y), point)
            || (alaskaPoint.point(x, y), point)
            || (hawaiiPoint.point(x, y), point);
    }

    albersUsa.convert = function (coordinates) {
        const ak = [[-179.13657211802118, 51.229087747767466], [179.77488070600702, 71.352561]];
        const hi = [[-160.24970712717126, 18.91727560534605], [-154.80833743387433, 22.23238695135951]];
        const [x, y] = coordinates
        return (y >= ak[0][1] && y <= ak[1][1] && x >= ak[0][0] && x <= ak[1][0] ? alaska
            : y >= hi[0][1] && y <= hi[1][1] && x >= hi[0][0] && x <= hi[1][0] ? hawaii
                : lower48)(coordinates);
    };

    albersUsa.invert = function (coordinates) {
        var k = lower48.scale(),
            t = lower48.translate(),
            x = (coordinates[0] - t[0]) / k,
            y = (coordinates[1] - t[1]) / k;
        return (y >= 0.120 && y < 0.234 && x >= -0.425 && x < -0.214 ? alaska
            : y >= 0.166 && y < .505 && x >= -0.36 && x < -0.115 ? hawaii
                : lower48).invert(coordinates);
    };

    albersUsa.stream = function (stream) {
        return cache && cacheStream === stream ? cache : cache = multiplex([lower48.stream(cacheStream = stream), alaska.stream(stream), hawaii.stream(stream)]);
    };

    albersUsa.precision = function (_) {
        if (!arguments.length) return lower48.precision();
        lower48.precision(_), alaska.precision(_), hawaii.precision(_);
        return reset();
    };

    albersUsa.scale = function (_) {
        if (!arguments.length) return lower48.scale();
        lower48.scale(_), alaska.scale(_ * 0.35), hawaii.scale(_);
        return albersUsa.translate(lower48.translate());
    };

    albersUsa.translate = function (_) {
        if (!arguments.length) return lower48.translate();
        var k = lower48.scale(), x = +_[0], y = +_[1];

        lower48Point = lower48
            .translate(_)
            .clipExtent([[x - 0.455 * k, y - k], [x + 0.53 * k, y + k]])
            .stream(pointStream);

        alaskaPoint = alaska
            .translate([x - 0.307 * k, y + 0.201 * k])
            .clipExtent([[x - 0.425 * k + epsilon, y + 0.120 * k + epsilon], [x - 0.214 * k - epsilon, y + 0.234 * k - epsilon]])
            .stream(pointStream);

        hawaiiPoint = hawaii
            .translate([x - 0.363 * k, y + 0.488 * k])
            .clipExtent([[x - 0.36 * k + epsilon, y + 0.166 * k + epsilon], [x - 0.115 * k - epsilon, y + .505 * k - epsilon]])
            .stream(pointStream);

        return reset();
    };

    function multiplex(streams) {
        const n = streams.length;
        return {
            point(x, y) { for (const s of streams) s.point(x, y); },
            sphere() { for (const s of streams) s.sphere(); },
            lineStart() { for (const s of streams) s.lineStart(); },
            lineEnd() { for (const s of streams) s.lineEnd(); },
            polygonStart() { for (const s of streams) s.polygonStart(); },
            polygonEnd() { for (const s of streams) s.polygonEnd(); }
        };
    }

    function reset () {
        cache = cacheStream = null;
        return albersUsa;
    }

    return albersUsa.scale(1070);
}

export { Projection }

export default function ({ topologyUrl }) {
    return {
        countries: null,
        features: null,
        land: null,
        states: null,
        topology: null,
        async init () {
            this.topology = await fetch(topologyUrl).then((response) => response.json());
            this.countries = feature(this.topology, this.topology.objects.countries).features;
            this.land = feature(this.topology, this.topology.objects.land).features;
            this.states = feature(this.topology, this.topology.objects.states).features;
            this.features = [...this.countries, ...this.states].map((e) => {
                switch (e.properties.name) {
                    case 'Alabama':
                    case 'Delaware':
                    case 'Maine':
                        e.properties.color = '#2A7C69';
                        break;
                    case 'Arizona':
                    case 'El Salvador':
                        e.properties.color = '#F3D4B5';
                        break;
                    case 'Arkansas':
                    case 'Florida':
                    case 'Illinois':
                    case 'New Hampshire':
                        e.properties.color = '#7AD6BC';
                        break;
                    case 'Bahamas':
                    case 'Iowa':
                    case 'Oklahoma':
                    case 'Puerto Rico':
                    case 'Vermont':
                    case 'Virginia':
                        e.properties.color = '#94D78A';
                        break;
                    case 'Belize':
                    case 'Colorado':
                        e.properties.color = '#C9A85F';
                        break;
                    case 'Costa Rica':
                    case 'Nebraska':
                        e.properties.color = '#EFD15D';
                        break;
                    case 'California':
                    case 'Honduras':
                    case 'Texas':
                        e.properties.color = '#CC765C';
                        break;
                    case 'Connecticut':
                    case 'Kentucky':
                    case 'Maryland':
                    case 'North Dakota':
                        e.properties.color = '#0DBC98';
                        break;
                    case 'Cuba':
                    case 'New Jersey':
                    case 'Nicaragua':
                    case 'Utah':
                        e.properties.color = '#DE8F73';
                        break;
                    case 'District of Columbia':
                    case 'Mexico':
                        e.properties.color = '#FFBE4F';
                        break;
                    case 'Dominican Rep.':
                        e.properties.color = '#3AD1AB';
                        break;
                    case 'Georgia':
                    case 'Massachusetts':
                    case 'Missouri':
                    case 'South Dakota':
                    case 'West Virginia':
                        e.properties.color = '#83C9A4';
                        break;
                    case 'Haiti':
                    case 'Indiana':
                    case 'Louisiana':
                    case 'Oregon':
                    case 'Rhode Island':
                    case 'South Carolina':
                        e.properties.color = '#7AB597';
                        break;
                    case 'Hawaii':
                        e.properties.color = '#1FB795';
                        break;
                    case 'Idaho':
                    case 'Ohio':
                    case 'Minnesota':
                        e.properties.color = '#8ABC69';
                        break;
                    case 'Guatemala':
                    case 'Jamaica':
                    case 'Nevada':
                    case 'New Mexico':
                        e.properties.color = '#D4994A';
                        break;
                    case 'Kansas':
                    case 'New York':
                    case 'Wyoming':
                        e.properties.color = '#C2D68B';
                        break;
                    case 'Michigan':
                    case 'Tennessee':
                        e.properties.color = '#26C6B3';
                        break;
                    case 'Mississippi':
                    case 'North Carolina':
                    case 'Pennsylvania':
                    case 'Washington':
                    case 'Wisconsin':
                        e.properties.color = '#499D90';
                        break;
                    case 'Montana':
                        e.properties.color = '#478976';
                        break;
                    default:
                        e.properties.color = '#B4E0E2';
                        break;
                }
                return e
            });
        }
    }
}
