import { Projection } from './vacatiaplusLandingDestinations'
import { geoPath } from 'd3-geo'
import { select } from 'd3-selection'

export default function ({ destinations, featureName, paddingTop = 0, paddingBottom = 0 }) {
    return {
        render () {
            if (!this.topology) { return }

            const { height, width } = this;

            const featureStroke = 2;
            const landStroke = Math.min(12, Math.max(10, 10 / 327 * width)) + featureStroke;
            const projection = Projection().scale(1).translate([0, 0]);
            const path = geoPath(projection);
            const feature = this.states.find((e) => e.properties.name === featureName);

            // Compute the bounds of a feature of interest, then derive scale & translate.
            const b = path.bounds({ type: "FeatureCollection", features: [ feature ] });
            const w = width - landStroke;
            const h = height - paddingTop - paddingBottom - landStroke;
            const s = 1 / Math.max((b[1][0] - b[0][0]) / (w), (b[1][1] - b[0][1]) / (h));
            const t = [(width - s * (b[1][0] + b[0][0])) / 2, (height - s * (b[1][1] + b[0][1])) / 2];

            // Update the projection to use computed scale & translate.
            projection
                .scale(s)
                .translate(t);

            const svg = select(this.$el);

            // land
            svg
                .selectAll('path.land')
                .data(this.land)
                .join('path')
                    .attr('d', path)
                    .attr('fill', '#B4E0E2')
                    .attr('stroke', '#B4E0E2')
                    .attr('stroke-linejoin', 'round')
                    .attr('stroke-width', landStroke)
                    .classed('land', true);

            // features (countries and states)
            svg
                .selectAll('path.feature')
                .data(this.features)
                .join('path')
                    .attr('d', path)
                    .attr('fill', (d) => d.properties.color)
                    .attr('stroke', (d) => d.properties.color)
                    .attr('stroke-linejoin', 'round')
                    .attr('stroke-width', featureStroke)
                    .classed('feature', true);

            // resort marker
            const markerScale = Math.min(2, Math.max(1, width / 327));
            const resort = svg
                .selectAll('g.resort')
                .data(destinations.resorts)
                .join('g')
                    .attr('transform', d => {
                        const { latitude, longitude } = d.coordinates;
                        const t = projection.convert([longitude, latitude]);
                        return `translate(${t}) scale(${markerScale})`
                    })
                    .classed('resort', true);
            resort
                .selectAll('title')
                .data(d => [d])
                .join('title')
                .text(d => d.name)
            resort
                .selectAll('g')
                .data([0])
                .join('g')
                .html(`
                    <path d="M-1.32224 16C-1.46876 16 -1.56644 16 -1.71297 16H-1.95717C-4.15503 15.7558 -5.86448 14.3394 -6.40174 12.3857C-6.74362 11.0182 -6.45058 9.94367 -6.25521 9.35758C-6.20637 9.2599 -6.20637 9.16221 -6.15753 9.01569C-6.05985 8.47843 -6.05985 7.55045 -6.10869 6.47594C-6.40173 6.52478 -6.64594 6.62247 -6.93899 6.67131C-7.57393 6.81783 -8.69728 7.11088 -9.96715 7.11088C-11.5789 7.11088 -12.9465 6.67131 -14.1675 5.841L-14.314 5.74332C-15.8281 4.66881 -18.6121 2.66632 -17.8794 -0.606049C-17.1468 -3.73189 -14.021 -4.22031 -12.2139 -4.22031C-12.165 -4.22031 -12.1162 -4.22031 -12.0673 -4.22031C-10.7975 -4.22031 -9.03917 -4.36683 -6.89015 -4.70872C-6.89015 -8.17645 -6.45058 -11.4977 -4.64345 -13.6467C-3.17821 -15.405 -1.07803 -16.2353 1.31519 -15.9422C3.41537 -15.698 5.02713 -14.3305 5.51555 -12.328C5.85744 -10.9604 5.56439 -9.8859 5.36902 -9.2998C5.32018 -9.20212 5.32018 -9.10444 5.27134 -8.95792C5.17366 -8.51834 5.17366 -7.78572 5.2225 -6.90658C8.64139 -7.29731 12.1091 -7.10194 14.8442 -5.14829C16.6025 -3.87842 17.6282 -2.31549 17.9212 -0.557208C18.1166 0.761508 17.8724 2.08022 17.1886 3.20357C17.0421 3.44778 16.6514 4.08272 15.9187 4.61997L14.2581 5.88985L12.3045 5.15723C10.1066 4.32692 8.25066 3.93619 6.19933 3.98503C6.34585 7.59929 6.00396 11.0182 4.19683 13.3626C2.9758 15.0232 0.973305 16 -1.32224 16Z" fill="white"/>
                    <path d="M14.5027 1.34762C14.405 1.49414 14.2585 1.73835 14.0631 1.88487L13.8189 2.08024L13.5747 1.98255C9.56971 0.419632 6.44387 0.273108 2.73193 0.908045V1.00573C3.12266 4.86419 3.26918 9.11339 1.65742 11.2624C0.924797 12.1904 -0.0520273 12.6788 -1.37074 12.6788C-1.46843 12.6788 -1.51727 12.6788 -1.61495 12.6788C-1.85916 12.63 -2.98251 12.4834 -3.22671 11.5066C-3.3244 11.067 -3.22671 10.6763 -3.12903 10.2856C-3.08019 10.0902 -3.03135 9.89485 -2.98251 9.65064C-2.64062 8.13656 -2.88482 5.25492 -3.12903 2.22676C-3.66629 2.37328 -4.25238 2.51981 -4.83848 2.66633C-5.76646 2.91054 -6.74329 3.15475 -7.76896 3.44779C-8.30621 3.59432 -9.08767 3.78968 -9.96682 3.78968C-10.6994 3.78968 -11.5297 3.64316 -12.2135 3.1059L-12.3112 3.00822C-13.6788 2.03139 -14.8021 1.10341 -14.6067 0.077742C-14.4114 -0.850243 -13.2392 -0.947926 -12.1158 -0.947926C-12.067 -0.947926 -12.067 -0.947926 -12.0182 -0.947926C-9.4784 -0.947926 -5.91299 -1.48518 -3.37324 -1.92475C-3.61744 -5.92974 -3.51976 -9.73936 -2.00568 -11.5953C-1.27306 -12.4745 -0.247393 -12.8652 0.97364 -12.7187C1.95047 -12.5722 2.29235 -11.9372 2.39004 -11.5465C2.48772 -11.1069 2.39004 -10.7162 2.29236 -10.3255C2.24351 -10.1301 2.19467 -9.93473 2.14583 -9.69052C1.85278 -8.32296 2.04815 -6.02742 2.3412 -3.14579C7.02996 -4.12261 10.6442 -4.26914 12.9886 -2.60853C13.6724 -2.12012 14.5515 -1.28981 14.7469 -0.166465C14.8934 0.37079 14.7469 0.908044 14.5027 1.34762Z" fill="#2E95EC"/>
                `)
        }
    }
}
