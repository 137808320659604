import Swiper from 'swiper/bundle'
import $ from 'jquery'

new Swiper('.swiper-hotel-hero', {
    autoplay: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
});

new Swiper('.swiper-resort-hero', {
    effect: 'fade',
    speed: 500,
    autoplay: {
        delay: 10000,
    },
    fadeEffect: {
        crossFade: true,
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    on: {
        slideChange(swiper) {
            dispatchEvent(new CustomEvent('resort-hero-change', { detail: swiper }))
        },
    },
});

new Swiper('.swiper-vpl-landing-resorts', {
    spaceBetween: 20,

    breakpoints: {
        // xs
        360: {
            slidesPerView: 1,
            spaceBetween: 10
        },
        // sm
        640: {
            slidesPerView: 2,
            spaceBetween: 10
        },
        // lg
        1024: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        // xl
        1280: {
            slidesPerView: 4,
            spaceBetween: 20
        }
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

new Swiper('.swiper-vpl-landing-testimonials', {
    loop: true,
    spaceBetween: 100,

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

new Swiper('.swiper-vpl-hero', {
    effect: 'fade',
    speed: 500,
    autoplay: {
        delay: 5000,
    },
    fadeEffect: {
        crossFade: true,
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    on: {
        slideChange(swiper) {
            dispatchEvent(new CustomEvent('vpl-hero-change', { detail: swiper }))
        },
    },
});

const $swiperPointsCharts = $('.swiper-points-charts')
if ($swiperPointsCharts.length) {
    new Swiper($swiperPointsCharts[0], {
        autoHeight: true,
        initialSlide: $swiperPointsCharts.data('initial-slide') || 0,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        spaceBetween: 16,
        breakpoints: {
            768: {
                spaceBetween: 0,
            },
        },
    });
}
