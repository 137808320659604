import newError from './util/newError'
import formatDate from './util/formatDate'
import normalizeDate from './util/normalizeDate'
import validateDates from './util/validateDates'


export default function({ owner = {}, ...props }) {
  const defaultDate = { month: null, day: null, year: null }
  const {
    arrival = props.arrival ?? defaultDate,
    departure = props.departure ?? defaultDate,
    adults = props.adults ?? 0,
    children = props.children ?? 0,
    destination = props.destination ?? '',
  } = JSON.parse(sessionStorage.getItem("ownerplusWidgetData")) || {}

  return {
    arrival,
    departure,
    adults,
    children,
    destination,
    owner: {
      roomType: owner.roomType ?? null,
      arrival: owner.arrival ?? defaultDate,
      departure: owner.departure ?? defaultDate,
      points: owner.points ?? 0,
    },
    error: null,

    normalizeDate,

    init() {
      const notify = () => this.normalizeDate(['arrival', 'departure'])

      this.$watch('arrival', notify)
      this.$watch('departure', notify)
    },

    date({ month, day, year}) {
      return formatDate(new Date(year, month, day))
    },

    submit(e) {
      this.error = this.validateSearch()
      if (this.error) {
        e.preventDefault()
      }

      const ownerplusWidgetData = JSON.stringify({
        ...JSON.parse(sessionStorage.getItem("ownerplusWidgetData")),
        arrival: this.arrival,
        departure: this.departure,
        destination: this.destination,
        adults: this.adults,
        children: this.children,
      })

      sessionStorage.setItem("ownerplusWidgetData", ownerplusWidgetData)
    },

    validateSearch() {
      const { arrival, departure, adults, destination } = this

      const datesError = validateDates(arrival, departure, { onlyFuture: true })
      if (datesError) {
        return datesError
      }

      if (!adults) {
        return newError('Please select the number of adults (18+) and children (0-17) who will be traveling.', ['adults'])
      }

      if (!destination) {
        return newError('Please select one of our popular destinations.', ['destination'])
      }
    },
  }
}
