import { Projection } from './vacatiaplusLandingDestinations'
import { geoPath } from 'd3-geo'
import { select } from 'd3-selection'

export default function ({ destinations }) {
    return {
        render () {
            if (!this.topology) { return }

            const { height, width } = this;

            const featureStroke = 2;
            const landStroke = Math.min(6, Math.max(4, 4 / 327 * width)) + featureStroke;
            const projection = Projection().scale(1).translate([0, 0]);
            const path = geoPath(projection);

            // Compute the bounds of a feature of interest, then derive scale & translate.
            const b = [[-0.36079035425033884, -0.224638063532401], [0.525, 0.507]]; // manualy adjusted to exclude the Galápagos (otherwise we'd use path.bounds)
            const w = width - landStroke;
            const h = height - landStroke;
            const s = 1 / Math.max((b[1][0] - b[0][0]) / (w), (b[1][1] - b[0][1]) / (h));
            const t = [(width - s * (b[1][0] + b[0][0])) / 2, (height - s * (b[1][1] + b[0][1])) / 2];

            // Update the projection to use computed scale & translate.
            projection
                .scale(s)
                .translate(t);

            const svg = select(this.$el);

            // land
            svg
                .selectAll('path.land')
                .data(this.land)
                .join('path')
                    .attr('d', path)
                    .attr('fill', '#B4E0E2')
                    .attr('stroke', '#B4E0E2')
                    .attr('stroke-linejoin', 'round')
                    .attr('stroke-width', landStroke)
                    .classed('land', true);

            // features (countries and states)
            svg
                .selectAll('path.feature')
                .data(this.features)
                .join('path')
                    .attr('d', path)
                    .attr('fill', (d) => d.properties.color)
                    .attr('stroke', (d) => d.properties.color)
                    .attr('stroke-linejoin', 'round')
                    .attr('stroke-width', featureStroke)
                    .classed('feature', true);

            // markers
            const markerScale = Math.min(2, Math.max(1, width / 327));
            const transform = (d) => {
                const { latitude, longitude } = d.coordinates;
                const t = projection.convert([longitude, latitude]);
                return `translate(${t}) scale(${markerScale})`
            }

            // destination marker
            const destination = svg
                .selectAll('g.destination')
                .data(destinations.other)
                .join('g')
                    .attr('transform', transform)
                    .classed('destination', true);
            destination
                .selectAll('title')
                .data(d => [d])
                .join('title')
                .text(d => d.name)
            destination
                .selectAll('g')
                .data([0])
                .join('g')
                .html(`<rect x="-2" y="-2" width="4" height="4" rx="1" fill="#FFF200"/>`)

            // resort marker
            const resort = svg
                .selectAll('g.resort')
                .data(destinations.resorts)
                .join('g')
                    .attr('transform', transform)
                    .classed('resort', true);
            resort
                .selectAll('title')
                .data(d => [d])
                .join('title')
                .text(d => d.name)
            resort
                .selectAll('g')
                .data([0])
                .join('g')
                .html(`
                    <path d="M-0.439848 5.23652C-0.487818 5.23652 -0.519797 5.23652 -0.567768 5.23652H-0.647719C-1.36728 5.15657 -1.92694 4.69285 -2.10283 4.05324C-2.21476 3.60552 -2.11882 3.25374 -2.05486 3.06186C-2.03887 3.02988 -2.03887 2.99789 -2.02288 2.94992C-1.9909 2.77403 -1.9909 2.47022 -2.00689 2.11843C-2.10283 2.13442 -2.18278 2.16641 -2.27872 2.1824C-2.4866 2.23037 -2.85437 2.3263 -3.27011 2.3263C-3.79779 2.3263 -4.24552 2.18239 -4.64527 1.91056L-4.69325 1.87858C-5.18894 1.52679 -6.10038 0.871193 -5.86053 -0.200151C-5.62068 -1.22353 -4.5973 -1.38343 -4.00566 -1.38343C-3.98967 -1.38343 -3.97369 -1.38343 -3.9417 -1.38343C-3.54195 -1.38343 -2.95031 -1.4314 -2.24674 -1.54333C-2.24674 -2.67864 -2.10283 -3.76597 -1.51119 -4.46954C-1.03148 -5.04519 -0.343907 -5.31703 0.439614 -5.22108C1.12719 -5.14113 1.65487 -4.6934 1.81477 -4.0378C1.92671 -3.59008 1.83077 -3.23829 1.76681 -3.04641C1.75082 -3.01443 1.75081 -2.98245 1.73482 -2.93448C1.70284 -2.79056 1.70284 -2.55072 1.71883 -2.26289C2.83815 -2.39082 3.97346 -2.32685 4.86891 -1.68724C5.44456 -1.27149 5.78035 -0.759813 5.87629 -0.184165C5.94025 0.247571 5.8603 0.679309 5.63644 1.04708C5.58847 1.12703 5.46054 1.33491 5.22069 1.5108L4.67702 1.92655L4.03741 1.68669C3.31785 1.41486 2.71023 1.28694 2.03864 1.30293C2.08661 2.48621 1.97467 3.60552 1.38304 4.37305C0.967291 4.91672 0.311692 5.23652 -0.439848 5.23652Z" fill="white"/>
                    <path d="M4.725 0.439447C4.69302 0.487417 4.64504 0.567364 4.58108 0.615335L4.51712 0.663314L4.43717 0.631331C3.12597 0.119644 2.1026 0.0716715 0.887343 0.279544V0.311527C1.01527 1.57475 1.06323 2.9659 0.535557 3.66947C0.295703 3.97329 -0.0241033 4.13318 -0.455839 4.13318C-0.48782 4.13318 -0.50381 4.13318 -0.53579 4.13318C-0.615741 4.11719 -0.983514 4.06923 -1.06347 3.74942C-1.09545 3.60551 -1.06346 3.47759 -1.03148 3.34967C-1.01549 3.28571 -0.999505 3.22175 -0.983514 3.1418C-0.871583 2.6461 -0.951532 1.70268 -1.03148 0.711282C-1.20738 0.759253 -1.39926 0.807218 -1.59114 0.855188C-1.89495 0.935139 -2.21476 1.0151 -2.55055 1.11104C-2.72645 1.15901 -2.98229 1.22297 -3.27011 1.22297C-3.50997 1.22297 -3.7818 1.175 -4.00566 0.999105L-4.05364 0.967122C-4.50136 0.647318 -4.86914 0.343503 -4.80518 0.00770864C-4.74121 -0.296106 -4.35745 -0.328082 -3.98967 -0.328082C-3.97368 -0.328082 -3.97369 -0.328082 -3.9577 -0.328082C-3.1262 -0.328082 -1.95892 -0.503975 -1.12743 -0.647886C-1.20738 -1.95908 -1.1754 -3.20632 -0.679701 -3.81395C-0.439848 -4.10177 -0.104052 -4.2297 0.295703 -4.18173C0.615507 -4.13376 0.727438 -3.92588 0.759419 -3.79796C0.791399 -3.65405 0.759422 -3.52613 0.727441 -3.39821C0.711451 -3.33425 0.695458 -3.27029 0.679468 -3.19034C0.583526 -2.74261 0.647486 -1.99106 0.743428 -1.04764C2.27849 -1.36745 3.46177 -1.41542 4.2293 -0.871753C4.45316 -0.711851 4.74099 -0.440018 4.80495 -0.0722425C4.85292 0.13563 4.82094 0.295535 4.725 0.439447Z" fill="#2E95EC"/>
                `)
        }
    }
}
