import memoize from 'lodash/memoize'

export default function({ properties, conversion }) {
    // returns a points table for the supplied property using the supplied currency
    const pointsTable = memoize((property, currency) => {
        const { pointsData } = property
        let lowest, highest;

        const cells = [
            {
                text: 'Travel Season',
                style: 'header',
                row: 'first',
                column: 'first',
            },
        ]

        pointsData['rooms'].forEach((room, index, rooms) => {
            cells.push({
                text: room,
                style: 'header',
                row: 'first',
                column: rooms[index + 1] ? null : 'last',
                nowrap: true,
            })
        })

        pointsData['seasons'].forEach((season, index, seasons) => {
            const style = 0 === index % 2 ? 'odd' : 'even'
            const row = seasons[index + 1] ? null : 'last'

            cells.push({
                text: season['name'],
                nowrap: true,
                column: 'first',
                row,
                style,
            })

            season['rates'].forEach((rate, index, rates) => {
                const room = pointsData['rooms'][index]
                const nights = Math.floor(100000 / (rate / 7)) // convert from weekly to nightly rate

                if (!lowest || lowest.rate >= rate) {
                    lowest = { room, rate, season: season['name'], nights }
                }

                if (!highest || highest.rate < rate) {
                    highest = { room, rate, season: season['name'], nights }
                }

                cells.push({
                    text: currency === 'points'
                        ? rate.toLocaleString('en-US')
                        : `$${Math.round(rate * conversion).toLocaleString('en-US')}`,
                    column: rates[index + 1] ? null : 'last',
                    row,
                    style,
                })
            })
        })

        return {
            columns: pointsData['rooms'].length + 1,
            rows: pointsData['seasons'].length + 1,
            cells,
            lowest,
            highest,
        }
    }, (property, currency) => `${property['id']}|${currency}`)

    return {
        current: 0,
        scrollLeft: 0,
        searchUrl: null,
        showPointsChart: false,
        currency: 'points',
        properties,
        conversion,

        init() {
            this.$watch('showPointsChart', modal => {
                document.body.style.overflow = modal ? 'hidden' : 'visible'

                if (modal) this.$nextTick(() => {
                    this.currency = 'points'
                })
            })
        },

        pointsTable() {
            const { properties, current, currency } = this
            return pointsTable(properties[current], currency)
        },

        onClickArrow({ isLeftArrow = false } = {}) {
            const defaultScrollSize = 500;
            const scrollSize = isLeftArrow ? -defaultScrollSize : defaultScrollSize;
            const scrollLeft = document.getElementById("resort-gallery-container").scrollLeft + scrollSize;

            this.scrollLeft = scrollLeft;
            document.getElementById("resort-gallery-container").scrollLeft = scrollLeft;
        },
    }
}
