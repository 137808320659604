import newError from './newError'

export default function(arrival, departure, options = {}) {
  const arrivalFields = [
    `${options.prefix ?? ''}arrival.month`,
    `${options.prefix ?? ''}arrival.day`,
    `${options.prefix ?? ''}arrival.year`,
  ]

  const departureFields = [
    `${options.prefix ?? ''}departure.month`,
    `${options.prefix ?? ''}departure.day`,
    `${options.prefix ?? ''}departure.year`,
  ]

  if (null === arrival.month || null === arrival.day || null === arrival.year) {
    return newError('Please select an arrival date.', arrivalFields)
  }

  if (null === departure.month || null === departure.day || null === departure.year) {
    return newError('Please select an departure date.', departureFields)
  }

  const arrivalDate = new Date(Date.UTC(arrival.year, arrival.month, arrival.day))
  const departureDate = new Date(Date.UTC(departure.year, departure.month, departure.day))

  if (options.onlyFuture ?? false) {
    const now = new Date()
    const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()))
    if (arrivalDate < today) {
      return newError('Arrival date must be in the future.', arrivalFields)
    }
  }

  if (arrivalDate >= departureDate) {
    return newError('Departure date must be after arrival date.', departureFields)
  }

  const limit = 28 * 24 * 60 * 60 * 1000
  if (departureDate - arrivalDate > limit) {
    return newError('Searches for more than 28 days are not allowed.', departureFields)
  }
}
