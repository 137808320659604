import braintree from 'braintree-web/client'

export default function({
    total,
    paymentPlan,
    paymentPlans,
    braintreeToken,
    cardAddress,
    cardZip,
    cardCountry
}) {
    return {
        total,
        paymentPlan,
        paymentPlans,

        braintreeToken,
        braintreeNonce: null,

        upliftStatus: null,
        upliftCard: null,
        upliftOrderId: null,

        cardName: null,
        cardNumber: null,
        cardMonth: null,
        cardYear: null,
        cardCvv: null,
        cardAddress,
        cardZip,
        cardCountry,

        submitting: false,

        init() {
            this.$watch('paymentPlan', (value, oldValue) => {
                if (value === 'uplift') {
                    this.useUpliftCard()
                    this.$nextTick(() => { Uplift.Payments.select() })
                } else if (oldValue === 'uplift') {
                    this.clearCard()
                    this.$nextTick(() => { Uplift.Payments.deselect() })
                }
            })
        },

        clearCard() {
            this.cardName =
            this.cardNumber =
            this.cardMonth =
            this.cardYear =
            this.cardCvv =
            this.cardAddress =
            this.cardZip =
            this.cardCountry = null
        },

        useUpliftCard() {
            const { upliftCard } = this

            if (upliftCard) {
                this.cardName = upliftCard['name_on_card']
                this.cardNumber = upliftCard['card_number']
                this.cardMonth = upliftCard['expiration_month']
                this.cardYear = upliftCard['expiration_year']
                this.cardCvv = upliftCard['card_ccv']
                this.cardAddress = upliftCard['contact']['street_address']
                this.cardZip = upliftCard['contact']['postal_code']
                this.cardCountry = upliftCard['contact']['country']
            } else {
                this.clearCard()
            }
        },

        syncUpliftOrderId() {
            this.upliftOrderId = Uplift.Payments.getOrderId()
        },

        firstPayment() {
            const { paymentPlan, paymentPlans } = this

            if (paymentPlans[paymentPlan]) {
                return (paymentPlans[paymentPlan][Object.keys(paymentPlans[paymentPlan])[0]].amount / 100).toLocaleString('en-US', { minimumFractionDigits: 2 })
            }
        },

        submitDisabled() {
            const { paymentPlan, upliftStatus, submitting } = this

            if (submitting) {
                return true
            }

            return paymentPlan === 'uplift'
                && upliftStatus !== 'pending'
                && upliftStatus !== 'ready'
        },

        submitText() {
            const { paymentPlan, upliftStatus, submitting } = this

            if (submitting) {
                return 'Please wait…'
            }

            if (paymentPlan !== 'uplift') {
                return 'Complete your Purchase'
            }

            switch (upliftStatus) {
                case 'pending':
                    return 'Continue with Loan Application'
                case 'ready':
                    return 'Confirm Loan and Complete Booking'
                default:
                    return 'Complete your Uplift Loan Application'
            }
        },

        onUpliftApproved() {
            this.syncUpliftOrderId()
            this.upliftStatus = 'approved'
        },

        onUpliftRejected() {
            this.syncUpliftOrderId()
            this.upliftStatus = null
        },

        onUpliftRejectedContinue() {
            this.syncUpliftOrderId()
            this.paymentPlan = Object.keys(paymentPlans)[0]
            this.upliftStatus = 'rejected'
        },

        onUpliftConfirmReady() {
            this.syncUpliftOrderId()
            this.upliftStatus = 'pending'
        },

        onUpliftConfirmUnready() {
            this.syncUpliftOrderId()
            this.upliftStatus = null
        },

        onUpliftPaymentReady() {
            this.syncUpliftOrderId()
            this.upliftCard = Uplift.Payments.getVirtualCard()
            this.upliftStatus = 'ready'
        },

        onUpliftPaymentUnready() {
            this.syncUpliftOrderId()
            this.upliftCard = null
            this.upliftStatus = null
        },

        onSubmit(event) {
            const {
                paymentPlan,
                upliftStatus,
                submitting,
                braintreeNonce,
                braintreeToken,
                cardName,
                cardNumber,
                cardMonth,
                cardYear,
                cardCvv,
                cardAddress,
                cardZip,
                cardCountry,
            } = this

            if (submitting) {
                // the form is currently submitting and disabled
                event.preventDefault()
                return
            }

            if (paymentPlan === 'uplift' && upliftStatus === 'pending') {
                // ignore submit, continue uplift flow
                event.preventDefault()
                Uplift.Payments.clickNext()
                return
            }

            this.submitting = true

            if (!braintreeNonce) {
                event.preventDefault()

                // tokenize credit card information
                braintree.create({ authorization: braintreeToken }, (error, client) => {
                    if (error) {
                        // todo: handle error
                        alert('There was an error connecting to the payment gateway. Please try again later.');
                        this.submitting = false
                        throw error
                    }

                    client.request({
                        endpoint: 'payment_methods/credit_cards',
                        method: 'post',
                        data: {
                            creditCard: {
                                number: cardNumber,
                                cardholderName: cardName,
                                expirationMonth: cardMonth,
                                expirationYear: cardYear,
                                cvv: cardCvv,
                                billingAddress: {
                                    streetAddress: cardAddress,
                                    postalCode: cardZip,
                                    countryCodeAlpha2: cardCountry,
                                }
                            },
                        },
                    }, (error, response) => {
                        if (error) {
                            // todo: handle error
                            alert('We were unable to process your card. Please try again, or try a different card.');
                            this.submitting = false
                            throw error
                        }

                        // save the nonce and submit the form again
                        this.braintreeNonce = response.creditCards[0].nonce
                        this.$nextTick(() => { this.$refs.form.submit() })
                    })
                })
            }
        },
    }
}
