import $ from 'jquery'

export default function () {
    return {
        lazyloadFlooplan (id) {
            let $floorplanImage = $(`${id} .floor-plan`)
            let $floorplanLoader = $floorplanImage.next('.loader-wrapper')
            let $floorplanComingSoonImage = $floorplanLoader.next('.floor-plan-coming-soon')
            $floorplanImage
                .on('error', () => {
                    $floorplanImage.remove()
                    $floorplanLoader.addClass('tw-hidden')
                    $floorplanComingSoonImage.removeClass('tw-hidden')
                })
                .on('load', () => {
                    $floorplanLoader.addClass('tw-hidden')
                })
                .attr('src', $floorplanImage.data('src'))
        }
    }
}
