import Alpine from 'alpinejs'
import resortGallery from './resortGallery'
import resortPage from './resortPage'
import signupPay from './signupPay'
import numberOfGuestsSelect from './search/numberOfGuestsSelect'
import ownerplusWidget from './ownerplusWidget'
import ownerplusSearch from './ownerplusSearch'
import ownerPointsLookup from './ownerPointsLookup'
import vacatiaplusLandingDestinations from './vacatiaplusLandingDestinations'
import vacatiaplusLandingDestinationsModalMap from './vacatiaplusLandingDestinationsModalMap'
import vacatiaplusLandingResortCardMapSvg from './vacatiaplusLandingResortCardMapSvg'

window.Alpine = Alpine

// window.x is deprecated. use Alpine.data() instead
window.x = {
  resortGallery,
  signupPay,
  numberOfGuestsSelect,
}

Alpine.data('ownerplusSearch', ownerplusSearch)
Alpine.data('ownerplusWidget', ownerplusWidget)
Alpine.data('ownerPointsLookup', ownerPointsLookup)
Alpine.data('resortPage', resortPage)
Alpine.data('vacatiaplusLandingDestinations', vacatiaplusLandingDestinations)
Alpine.data('vacatiaplusLandingDestinationsModalMap', vacatiaplusLandingDestinationsModalMap)
Alpine.data('vacatiaplusLandingResortCardMapSvg', vacatiaplusLandingResortCardMapSvg)
Alpine.start()
