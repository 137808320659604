import map from 'lodash/map'
import toSafeInteger from 'lodash/toSafeInteger'

export default function ({ adults, children }) {
  return {
    open: false,
    adults: toSafeInteger(adults || 2),
    children: map(children, toSafeInteger),
    init () {
      this.$watch('adults', adults => {
        if (!adults) this.children = [];
        this.emitUpdate()
      })

      this.$watch('children', () => {
        this.emitUpdate()
      })
    },
    emitUpdate () {
        dispatchEvent(new CustomEvent('updated-number-of-guests', {
            detail: {
                adults: this.adults,
                children: this.children,
            },
        }));
    },
    incrementAdults (increment) {
      this.adults = Math.min(Math.max(this.adults + increment, 1), 12)
    },
    incrementChildren (increment) {
      if (increment > 0) {
        for (let i = 0; i < increment; i++) {
          this.children.push(0)
        }
      } else if (increment < 0) {
        for (let i = 0; i < Math.abs(increment); i++) {
          this.children.pop()
        }
      }
    },
    incrementChildAge (index, increment) {
      this.children[index] = Math.min(
        Math.max(this.children[index] + increment, 0),
        17
      )
    },
    summaryText () {
      const total = this.adults + this.children.length
      return `${total} Guest${1 === total ? '' : 's'}`
    },
    adultsText () {
      const { adults } = this
      return `${adults} Adult${1 === adults ? '' : 's'}`
    },
    childrenText () {
      const children = this.children.length
      if (!children) {
          return 'No children'
      }
      return `${children} Child${1 === children ? '' : 'ren'}`
    },
    childAgeLabel (index) {
      return `Age of Child ${index + 1}`
    },
    childAgeText (index) {
      const age = this.children[index]
      switch (age) {
        case 0:
          return '<1 Year'
        case 1:
          return '1 Year'
        default:
          return `${age} Years`
      }
    },
  }
}
