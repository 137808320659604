export default function({ pointsOptionsUrl, pointsResultUrl, inventoryOptionsUrl, inventoryResultUrl }) {
  return {
    points: {
      options: {
        roomTypes: [],
        years: [],
        weeks: [],
        checkInDay: false,
      },
      roomType: null,
      resort: null,
      week: null,
      year: null,
      checkInDay: null,
      result: {
        totalPoints: 0,
        dates: [],
        resort: null,
        roomType: null,
        blackout: false
      },
    },

    inventory: {
      options: {
        roomTypes: [],
      },
      roomType: null,
      resort: null,
      checkIn: null,
      checkOut: null,
      result: {
        inventory: false,
        totalPoints: 0,
        blackout: false,
        dates: {
          checkIn: null,
          checkOut: null,
        },
      },
    },

    init() {
      this.fetchPointsOptions()
    },

    onPointsResortChange() {
      this.points.options.roomTypes = []
      this.points.options.years = []
      this.points.options.weeks = []
      this.fetchPointsOptions()
    },

    onPointsSubmit() {
      this.fetchPointsResult()
    },

    onInventoryResortChange() {
      this.inventory.options.roomTypes = []
      this.fetchInventoryOptions()
    },

    onInventorySubmit() {
      this.fetchInventoryResult()
    },

    fetchPointsOptions() {
      fetch(pointsOptionsUrl, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          resort: this.points.resort,
          roomType: this.points.roomType,
          week: this.points.week,
          year: this.points.year,
        }),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
          .then(response => response.json())
          .then(data => {
            this.points.options = data
          })
          .catch(() => alert('There was an error.'))
    },

    fetchPointsResult() {
      fetch(pointsResultUrl, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          resort: this.points.resort,
          roomType: this.points.roomType,
          week: this.points.week,
          year: this.points.year,
          checkInDay: this.points.checkInDay,
        }),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
          .then(response => response.json())
          .then(data => {
            this.points.result = data
          })
          .catch(() => alert('There was an error.'))
    },

    fetchInventoryOptions() {
      fetch(inventoryOptionsUrl, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          resort: this.inventory.resort,
        }),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
          .then(response => response.json())
          .then(data => {
            this.inventory.options = data
          })
          .catch(() => alert('There was an error.'))
    },

    fetchInventoryResult() {
      fetch(inventoryResultUrl, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          resort: this.inventory.resort,
          roomType: this.inventory.roomType,
          checkIn: this.inventory.checkIn,
          checkOut: this.inventory.checkOut,
        }),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
          .then(response => response.json())
          .then(data => {
            this.inventory.result = data
          })
          .catch(() => alert('There was an error.'))
    },
  }
}
