import get from 'lodash/get'
import set from 'lodash/set'

export default function (fields) {
    fields.forEach(field => {
        const { month, day, year } = get(this, field)

        if (month === null || day === null || year === null) {
            // incomplete date
            return
        }

        const rawMonth = parseInt(month)
        const rawDay = parseInt(day)
        const rawYear = parseInt(year)
        const normal = new Date(rawYear, rawMonth, rawDay)
        const normalMonth = normal.getMonth()
        const normalDay = normal.getDate()
        const normalYear = normal.getFullYear()

        if (rawMonth === normalMonth && rawDay === normalDay && rawYear === normalYear) {
            // already normalized
            return
        }

        this.$nextTick(() => {
            set(this, field, {
                month: normalMonth,
                day: normalDay,
                year: normalYear,
            })
        })
    })
}
